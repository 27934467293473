import React from 'react';
function FooterButton() {

    return (
        <div className="bottom-container">
        <a className="bottom" href="/"  title="Satta king">SATTA KING</a>
        <a className="bottom" href="/about"  title="Satta king">ABOUT</a>
        <a className="bottom" href="/contact"  title="Satta king">CONTACT</a>
        <a className="bottom" href="/faq"  title="Satta king">FAQ</a>
        <a className="bottom" href="/disclaimer"  title="Satta king">DISCLAIMER</a>
        <a className="bottom" href="/privacypolicy"  title="Satta king">PRIVACY POLICY</a><br/><br/>
    </div>
    );
}

export default FooterButton;
